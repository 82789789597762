.login-container {
  padding: 64px 48px;
  border-radius: 10px;
  max-width: 400px;

  border: 1px solid rgba(101, 101, 101, 0.5);
}

@media only screen and (max-width: 600px) {
  .login-container {
    padding: 32px 24px;
  }
}
