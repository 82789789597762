@import url("https://fonts.googleapis.com/css?family=Abril+Fatface");

#policies {
  list-style-type: decimal-leading-zero;
  list-style: none;
  word-break: break-word;

  counter-reset: my-awesome-counter;
  margin: 0;
  padding: 0;
}
#policies > #title {
  counter-increment: my-awesome-counter;
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
}
#policies > #title > div > h4 {
  font-family: "Abril Fatface", serif;
  margin-bottom: 1rem;
}
#policies > #title::before {
  content: counter(my-awesome-counter, decimal-leading-zero);
  font-weight: bold;
  font-size: 3rem;
  margin-right: 1rem;
  font-family: "Abril Fatface", serif;
  line-height: 1;
  word-break: normal;
}

#policy-details {
  list-style: none;
  counter-reset: my-awesome-counter-2;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
#policy-details li {
  counter-increment: my-awesome-counter-2;
  display: flex;
  font-size: 1rem;
  width: 100%;

  margin-bottom: 0.5rem;
}
#policy-details li::before {
  content: counter(my-awesome-counter-2);
  width: 3rem;

  font-size: 1.5rem;
  margin-right: 0.5rem;
  font-family: "Abril Fatface", serif;
  line-height: 1;
}

#title {
  counter-reset: my-awesome-counter-sub-1;
}

#policy-subdetails {
  list-style: none;
  counter-reset: my-awesome-counter-sub-1;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  padding: 0;
}
#policy-subdetails li {
  counter-increment: my-awesome-counter-sub-1;
  display: flex;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 0.5rem;
}
#policy-subdetails li::before {
  content: counter(my-awesome-counter-sub-1);
  word-break: normal;
  font-size: 1rem;
  width: 1.25rem;
  padding-top: 0.3rem;
  margin-right: 0.5rem;
  font-family: "Abril Fatface", serif;
  line-height: 1;
}

.accordion-details {
  padding: 24px 36px;
}

.accordion {
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
}

@media only screen and (max-width: 600px) {
  .accordion-details {
    padding: 24px 12px;
  }

  #policies > #title::before {
    font-size: 2rem;
  }
  #policy-details li::before {
    font-size: 1.25rem;
  }
  #policy-subdetails li::before {
    font-size: 1rem;
  }

  #policy-details li {
    font-size: 0.9rem;
  }

  #policy-subdetails li {
    font-size: 0.9rem;
  }
  #policies > #title > div > p {
    font-size: 0.9rem;
  }
  #policy-details > li > div > p {
    font-size: 0.9rem;
  }
}
