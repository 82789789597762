.styled-txt-btn {
  padding: 6px 16px;
  border: 1px solid;
  border-radius: 4;
  margin-left: 12;
}

.nav-center-div {
  flex-grow: 1;
  display: block;
}

.link-btn {
  text-transform: none;
  transition: 0s;
  margin: 0px 8px;
  border-radius: 25px;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: inherit;
  background-color: inherit;
  font-family: inherit;
  margin: 0px 8px;
}

.dropdown-content {
  text-align: left;
  border-radius: 0px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 8px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content > * {
  padding-left: 8px;
}

.dropdown-content-link {
  border-radius: 4px;
}

.nav-icon {
  color: inherit;
}

.nav-menu-icon-btn {
  display: none;
}

@media only screen and (max-width: 600px) {
  .nav-center-div {
    display: none;
  }
  .nav-menu-icon-btn {
    display: inline-block;
  }
}
