.home-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  margin-top: -58px;
  padding: 0px 24px;
}
.heroBanner {
  padding: 0px 24px;
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  display: flex;
  justify-content: center;
}
.heroBanner-wrapper {
  padding: 56px 24px 96px 24px;
  max-width: 1100px;
  color: #fff;
  position: relative;
}

.hero-img {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url("../../assets/images/Hero-1-full.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: hard-light;
}

.contrast-txt {
  color: #fff;
}

.accord {
  box-shadow: rgba(45, 173, 126, 0.1) 0px 10px 15px -3px,
    rgba(45, 173, 126, 0.05) 0px 4px 6px -2px;
}

.stacked-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.description-txt {
  margin-top: 16px;
  white-space: pre-line;
  font-weight: 400;
  /* color: rgb(80, 81, 82); */
}

.txt-l {
  font-weight: 300;
}
.txt-bold {
  font-weight: 550;
}
.txt-bold-l {
  font-weight: 450;
}

.heroBanner-text {
  margin-bottom: 24px;
  margin-top: 8px;
  font-weight: 400;
}

.heroBanner-signup-button-family {
  background-color: #31bd8a;
  /* box-shadow: none; */
}
.heroBanner-signup-button-family:hover {
  background-color: #2dad7e;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
.section-wrapper {
  margin: 24px 0px;
  padding: 48px 24px;
  overflow: hidden;
  max-width: 1100px;
  width: 100%;
}
.inner-container-padding {
  padding: 32px 24px;
}

.comp-advan {
  padding: 0px 24px;
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  display: flex;
  justify-content: center;
  background: #ffffff;
}

.comp-advan-header {
  font-weight: 500;
  border-radius: 50px;
  padding: 12px 16px;
  margin-bottom: 36px;
}

.icon-container {
  padding: 12px;
  border-radius: 50%;

  display: flex;
  width: fit-content;
}

.icon-blue {
  color: rgba(35, 131, 243, 1);
  background: rgba(35, 131, 243, 0.1);
}

.icon-purple {
  color: rgba(89, 64, 137, 1);
  background: rgba(89, 64, 137, 0.1);
}

.icon-magenta {
  color: rgba(218, 10, 181, 1);
  background: rgba(218, 10, 181, 0.1);
}

.icon-green {
  color: rgba(44, 172, 124, 1);
  background: rgba(44, 172, 124, 0.1);
}

.icon-orange {
  color: rgba(247, 61, 22, 1);
  background: rgba(247, 61, 22, 0.1);
}
.icon-yellow {
  color: rgba(245, 200, 66, 1);
  background: rgba(245, 200, 66, 0.1);
}

.white-bg {
  background-color: #ffffff;
}
.p-bg-l {
  background-color: #67e0ad;
}
.p-bg {
  background-color: #2dad7e;
}
.p-bg-dark {
  background-color: #007d52;
}
.s-bg-l {
  background-color: #f05369;
}
.s-bg {
  background-color: #b8173f;
}
.s-bg-dark {
  background-color: #810019;
}

.service-grid-container {
  margin-top: 32px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.service-grid-item {
  padding: 8px 16px;
  transition: 0.3s;
}

.service-item-container {
  padding: 8px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 75px;
  height: 60px;
  text-overflow: wrap;
  /* word-break: break-word; */
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0px 1px,
    0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
    0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.service-grid-item:hover {
  /* padding: 24px;
  font-size: 24px; */
  transform: scale3d(1.15, 1.15, 1);
}

.grid-item {
  padding: 32px 24px;
}
.pricing-container {
  background: #ffffff;
  height: 100%;
  padding: 64px;
  border: 4px solid #2dad7e;
  border-radius: 65px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}
.pricing-wrapper {
  border-radius: 65px;
}

.get-started {
  background-color: #bdf7df;
}

.pop-out-img {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  border-radius: 25px;
  transform: scale3d(1.1, 1.1, 1);
  transition: 0.3s;
}

.absolute-centered {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.number-highlight {
  border-radius: 50%;
  width: 140px;
  height: 140px;
  background-color: honeydew;
}

.number-highlight-red {
  position: relative;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  background-color: rgb(255, 245, 240);
}

.txt-shadow-responsive {
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1);
}
.txt-shadow {
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1);
}

.curves {
  position: absolute;
  bottom: -1px;
  width: 100%;
  z-index: 2;
}

.curve {
  position: absolute;
  width: calc(100vw + 10px);
  bottom: 0;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.hero-about-us {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.35)),
    url("../../assets/images/family-2.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: hard-light;
}

.hero-padding {
  padding: calc(58px + 60px) 24px;
}

/* Animation */

.slide-responsive {
  animation: slide-right ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;

  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: slide-right ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/

  -moz-animation: slide-right ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: slide-right ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: slide-right ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/

  opacity: 1;
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translate(-50px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes slide-right {
  0% {
    opacity: 0;
    -moz-transform: translate(-50px, 0px);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes slide-right {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50px, 0px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes slide-right {
  0% {
    opacity: 0;
    -o-transform: translate(-50px, 0px);
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes slide-right {
  0% {
    opacity: 0;
    -ms-transform: translate(-50px, 0px);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}

@keyframes slide-down {
  0% {
    opacity: 1;
    transform: translate(0px, -50px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes slide-down {
  0% {
    opacity: 1;
    -moz-transform: translate(0px, -50px);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 1;
    -webkit-transform: translate(0px, -50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes slide-down {
  0% {
    opacity: 1;
    -o-transform: translate(0px, -50px);
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes slide-down {
  0% {
    opacity: 1;
    -ms-transform: translate(0px, -50px);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}

@media only screen and (max-width: 600px) {
  .pricing-container {
    padding: 40px 32px;
  }

  .heroBanner-wrapper {
    padding: 0;
    text-align: center;
  }

  .slide-responsive {
    animation: slide-down ease 1s;
    -webkit-animation: slide-down ease 1s;
    -moz-animation: slide-down ease 1s;
    -o-animation: slide-down ease 1s;
    -ms-animation: slide-down ease 1s;
  }
}

@media only screen and (max-width: 900px) {
  .txt-shadow-responsive {
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.4);
  }
  .grid-item {
    padding: 24px 30px;
  }
  .home-wrapper {
    padding: 0px 16px;
  }
  .section-wrapper {
    margin: auto 0px;
  }
  .pop-out-img {
    transform: scale3d(0.95, 0.95, 1);
  }
  .hero-img {
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
      url("../../assets/images/Hero-1-full.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: hard-light;
  }
}
