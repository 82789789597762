.footer-container {
  max-width: 1100px;
  padding: 36px 24px;
}
.footer-margin {
  margin: 0px 8px;
}
.hover-txt-primary {
  transition: 0.3s;
}
.hover-txt-primary:hover {
  color: #2dad7e;
}

@media only screen and (max-width: 600px) {
  .footer-container {
    padding: 24px;
  }
}
