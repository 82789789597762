.cv-hero-wrapper {
  border-radius: 25px;
  padding-right: 24px;
  padding-left: 24px;
  max-width: 1100px;
}
.cv-main {
  position: relative;
  margin-top: 24px;
}
.covid-img-1 {
  background: url("../../../assets//images//mask-1.jpeg");
  background-size: cover;
}

.img-cover {
  object-fit: cover;
}

.cv-hero-header {
  height: 100%;
  padding: 32px;
}

.cv-hero-img {
  background: url("../../../assets//images//mask-1.jpeg");
  background-size: cover;
  height: 100%;
  min-height: 400px;
  width: 100%;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  border-radius: 25px;
  transform: scale3d(1.05, 1.05, 1);
}
.cv-action-img {
  width: 100%;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}
.cv-ac-bg-img {
  background: url("../../../assets//images//care-bench.jpeg");
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 300px;
}
.cv-flex-hero {
  display: flex;
  justify-content: right;
}
.cv-msg {
  border-radius: 16px 75px 16px 75px;
  padding: 80px 40px;
  background-color: #fff;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  position: relative;
}
.cv-msg-txt {
  z-index: 10;
  position: relative;
}
.cv-blob-1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 800px;
  width: 800px;
  transform: rotate(150deg) translate(200px, 150px);
  z-index: 5;
  fill: rgba(251, 225, 230, 0.4);
  transition: 0.3s;
  animation: ripple 15s ease infinite;
}
.cv-blob-2 {
  position: absolute;
  bottom: -200px;
  right: -200px;
  height: 400px;
  width: 400px;
  z-index: 5;
  fill: rgba(225, 251, 240, 0.4);
  transition: 0.3s;
  animation: ripple-2 15s ease infinite;
}
@keyframes ripple {
  50% {
    transform: rotate(150deg) translate(200px, 150px) scale(1.5);
  }
  100% {
    transform: rotate(150deg) translate(200px, 150px) scale(1);
  }
}

@keyframes ripple-2 {
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 900px) {
  .cv-main {
    margin-top: 0px;
  }
  .cv-ac-bg-img {
    min-height: 300px;
    height: 100%;
    width: 100%;
  }
  .cv-hero-wrapper {
    border-radius: 25px 25px 25px 25px;
  }
  .cv-flex-hero {
    justify-content: flex-start;
  }
  .cv-hero-img {
    transform: none;
  }

  .cv-blob-1 {
    width: 500px;
    height: 500px;
  }
}
