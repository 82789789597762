html {
  height: 100%;
  min-height: -webkit-fill-available;
}
body {
  font-family: "Roboto", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  min-height: -webkit-fill-available;
  min-height: calc(100vh - 56px);
  position: relative;
  margin: 0;
}
.container {
  min-height: calc(100vh);

  overflow: hidden;
  width: 100%;
  /* display: block; */
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}
.container > * {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.containerB {
  /* margin: 50px auto 0 auto; */
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  display: block;
  position: relative;
}
main {
  flex: 1;
  padding-top: 58px;
  display: flex;
  letter-spacing: 0.03em;
}

main > * {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
}

footer {
  margin-top: 12px;
  background-color: #f6f6f8;
  position: relative;
  z-index: 11;
  display: flex;
  justify-content: center;
}
.nav-container {
  margin: auto;
}
.nav-container svg {
  color: #fff;
}

a {
  text-decoration: none;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hideScroll {
  -ms-overflow-style: none;
}

.threeLineEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
}

.title-line {
  width: 50px;
  margin: 4px 0px;
  height: 4px;
  background-color: #17b890;
}

.flexbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.flexbox-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flexbox-centered-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.flexbox-spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.flexbox-spaced-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.flexbox-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.hover-text:hover {
  text-decoration: underline;
}
.lnk-txt-ul {
  text-decoration: underline;
}
.lnk-txt-ul:hover {
  color: #2dad7e;
}
.txt-link-btn {
  text-transform: none;
  padding: 0;
  margin-top: 24px;
  font-weight: 550;
}
.page-wrapper {
  max-width: 1100px;
  margin: 0 auto;

  padding: 0px 24px;
}
.full-vw-container {
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  display: flex;
  justify-content: center;
}

.full-page-container {
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  display: flex;
  justify-content: center;
}

.section-wrapper-inner {
  max-width: 1100px;
  padding: 48px 24px;
  position: relative;
}

.stn-margin {
  margin-top: 36px;
  margin-bottom: 36px;
}

.res-gr-con {
  padding: 12px 12px 0rem 12px;
}
/* Cards */

.display-card {
  width: 100%;
  max-width: 500px;
  padding: 24px;
  position: relative;
  box-shadow: rgba(51, 160, 105, 0.2) 0px 7px 29px 0px;
}
.display-card-white {
  width: 100%;
  max-width: 500px;
  padding: 48px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.8) 0px 7px 29px 0px;
  background-color: #ffffff;
}

.display-card-blk {
  width: 100%;
  padding: 64px 48px;
  position: relative;
  background-color: rgba(12, 12, 12, 0.65);
  transition: 0.3s;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 7px 29px 0px;
}

.display-card-blk:hover {
  background-color: rgba(12, 12, 12, 0.8);
}

.scale-hover:hover {
  transform: scale(1.1);
}

/** Background */
.blob {
  background: url("./assets/images/Hero-Blob-2.svg"),
    url("./assets/images/Hero-Blob-1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}

.blob-2 {
  background: url("./assets/images/Hero-Blob-3.svg"),
    url("./assets/images/Hero-Blob-4.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}
.blob-3 {
  background: url("./assets/images/Hero-Blob-3.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}

.bottom-drop {
  background-image: url("./assets/images/curve-2.svg"),
    url("./assets/images/curve.svg"), url("./assets/images/Hero-Blob-3.svg"),
    url("./assets/images/Hero-Blob-6.svg");
  background-repeat: no-repeat;
  background-position: bottom, bottom, center left 49%, center right 49%;
  background-size: 105%, 105%, auto, auto;
}

.section-title {
  padding: 48px 0px;
}
.header-title {
  padding: 12px 24px 64px 24px;
  width: 100%;
  text-align: center;
}

/** Responsive Media */

@media only screen and (max-width: 900px) {
  .responsive-text-center {
    text-align: center;
  }
  .responsive-text-left {
    text-align: left;
  }
  .hover-text {
    text-decoration: underline;
  }
  .section-title {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .header-title {
    padding-bottom: 36px;
  }
  .scale-hover:hover {
    transform: scale3d(1.05, 1.05, 1);
  }
}

@media only screen and (max-width: 600px) {
  .display-card-white {
    padding: 24px;
  }
  .res-gr-con {
    padding-right: 0rem;
    padding-left: 0rem;
  }
}

/** Animation CSS */

.pre-fade {
  opacity: 0;
}

.slide-up-fade-in {
  animation: slide-up-fade-in ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;

  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: slide-up-fade-in ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/

  -moz-animation: slide-up-fade-in ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: slide-up-fade-in ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: slide-up-fade-in ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/

  opacity: 0;
  opacity: 1\9;
}

.delay-1 {
  animation-delay: 100ms;
}
.delay-2 {
  animation-delay: 200ms;
}
.delay-3 {
  animation-delay: 300ms;
}
.delay-4 {
  animation-delay: 400ms;
}
.delay-5 {
  animation-delay: 500ms;
}
.delay-6 {
  animation-delay: 600ms;
}

@keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -moz-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -o-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -ms-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}
